import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useHeader } from "../../../logic/useHeader";
import { useDimensions } from "../../../logic/useDimension";

import Assets from "../Assets";

import { DesktopNav } from "./DesktopNav";
import { MobileNav } from "./MobileNav";
import Style from "./Menu.module.scss";
import SiteLogo from "../../SiteLogo";

const Menu = () => {
  const { width } = useDimensions();

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, [scroll, setScroll]);

  const { marquee, menu } = useHeader();

  return (
    <header
      className={
        `${scroll
          ? `${Style.site_header} ${Style.site_header_slim}`
          : Style.site_header} ${menu ? Style.site_header : `${Style.site_header} ${Style.site_header_loading}`}` 
      }
    >
      {width >= 1200 && (
        <marquee behavior="scroll" direction="left" className={Style.site_header_topbar}>{marquee}</marquee>
      )}
      <div className="container">
        <div className={Style.site_header_wrapper}>
          <div className={Style.site_header_logo}>
            <Link to={"/"} title={"Pentad Securities - Research Beyond Investing"}>
              <SiteLogo />
            </Link>
          </div>
          <div className={Style.site_header_navbar}>
            {width >= 1200 && <DesktopNav menu={menu} />}
            {width < 1200 && <MobileNav />}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Menu;
