import React from "react";

import Style from "./Footer.module.scss";
import Assets from "../Assets";

import FooterMobileNav from "./FooterMobileNav";
import FooterDesktopNav from "./FooterDesktopNav";
import { useFooter } from "../../../logic/useFooter";
import { useState } from "react";
import { useDimensions } from "../../../logic/useDimension";
import ScrollArrow from "../ScrollArrow";
const Footer = () => {
  const parse = require("html-react-parser");

  const { footer_commondetails, footer_menu, footerContent } = useFooter();
  const [isReadMore, setIsReadMore] = useState(true);
  const { width } = useDimensions();

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className={Style.site_footer}>
      <div className='container'>
        <div className={Style.site_footer_wrapper}>
          <div className={Style.site_footer_contact}>
            <div className={Style.site_footer_contact_wrapper}>
              {width > 768 && (
                <figure className={Style.site_footer_logo}>
                  <img src={Assets.siteLogo} alt='Pentad Securities' />
                </figure>
              )}
              {width < 768 && (
                <div className={Style.site_footer_connect}>
                  <p className='fw-500'>Registered Office</p>
                  <p>
                    {
                      footer_commondetails?.wac_address_title?.[0]
                        ?.address_content
                    }
                  </p>
                  <div className={Style.social_media}>
                    <ul>
                      {footer_commondetails?.social_media_list?.map(
                        (value, index) => {
                          return (
                            <li key={index}>
                              <a
                                href={value?.social_media_url}
                                target={"_blank"}
                                rel={"noreferrer"}
                              >
                                <img
                                  src={value?.social_media_icons}
                                  size={18.84}
                                  alt='Icons'
                                />
                              </a>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              )}
              <FooterMobileNav footer_menu={footer_menu} />{" "}
              {width >= 768 && (
                <div className={Style.site_footer_connect}>
                  <p>
                    {
                      footer_commondetails?.wac_address_title?.[0]
                        ?.address_content
                    }
                  </p>
                  <div className={Style.social_media}>
                    <ul>
                      {footer_commondetails?.social_media_list?.map(
                        (value, index) => {
                          return (
                            <li key={index}>
                              <a
                                href={value?.social_media_url}
                                target={"_blank"}
                                rel={"noreferrer"}
                              >
                                <img
                                  src={value?.social_media_icons}
                                  size={18.84}
                                  alt='Icons'
                                />
                              </a>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <FooterDesktopNav footer_menu={footer_menu} />
          {width < 768 ? (
            <div className={Style.site_footer_content}>
              {footerContent &&
                parse(
                  isReadMore ? footerContent?.slice(0, 150) : footerContent
                )}
              <span onClick={toggleReadMore}>
                {isReadMore ? "...Read More" : " Show less"}
              </span>
            </div>
          ) : (
            <div className={Style.site_footer_content}>
              {footerContent &&
                parse(
                  isReadMore ? footerContent?.slice(0, 380) : footerContent
                )}
              &nbsp;&nbsp;
              <span onClick={toggleReadMore}>
                {isReadMore ? "...Read More" : " Show less"}
              </span>
            </div>
          )}
        </div>
        <div className={Style.site_footer_bottom}>
          <div className={Style.copyright}>
            {year} © Pentad Securities Private Limited. All rights reserved
          </div>
          <div className={Style.designedby}>
            Designed by{" "}
            <a
              href={"https://webandcrafts.com/"}
              target={"_blank"}
              rel='noreferrer'
            >
              Webandcrafts
            </a>
          </div>
        </div>
      </div>
      {width >= 1200 && <ScrollArrow />}
    </footer>
  );
};

export default Footer;
