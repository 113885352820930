import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  grievances: null,
};

export const getGrievances = createAsyncThunk(
  "grievances",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/investor-grievance-page");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getGrievancesSlice = createSlice({
  name: "grievances",
  initialState,
  reducers: {},
  extraReducers: {
    [getGrievances.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getGrievances.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.grievances = action.payload?.data;
    },
    [getGrievances.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getGrievancesSlice.reducer;
